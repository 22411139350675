var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"min-h-100vh bg-hex-F5F7F9"},[_c('div',{staticClass:"bg"}),_c('div',{ref:"list",staticClass:"relative"},[_c('van-pull-refresh',{on:{"refresh":_vm.onRefresh},model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('van-list',{attrs:{"immediate-check":false,"finished":_vm.finished,"finished-text":_vm.list.length ? '没有更多了' : '暂无数据'},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"bg-white rounded-4 mx-12 mt-12"},[_c('div',{staticClass:"text-16 font-bold py-16 px-12"},[_vm._v(" "+_vm._s(item.customerName)+" - "+_vm._s(item.bizNo)+" ")]),_vm._l(([
              {
                label: '联系人',
                value: `${
                  _vm.relationshipList.find((it) => it.value == item.relationship)
                    ?.displayName || ''
                }-${item.name || ''}-${item.mobile || ''}`,
              },
              {
                label: '催收结果',
                value: item.collectionResult,
              },
              {
                label: '是否结清',
                value: item.settleStatus,
              },
              {
                label: '是否继续催收',
                value: item.continueCollectStatus,
              },
              {
                label: '跟进措施',
                value: item.measures,
              },
              {
                label: '催收人',
                value: item.collectorName,
              },
              {
                label: '催收日期',
                value: item.collectionDate,
              },
            ]),function(item1,index1){return _c('div',{key:index1},[(index1 > 0)?_c('div',{staticClass:"h-1px bg-hex-F2F5F5 mx-12"}):_vm._e(),_c('div',{staticClass:"flex text-14 leading-none py-16 px-12"},[_c('div',{staticClass:"text-hex-666"},[_vm._v(_vm._s(item1.label)+"：")]),_c('div',[_vm._v(_vm._s(item1.value || "-"))])])])})],2)}),0)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }