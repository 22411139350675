<template>
  <div class="min-h-100vh bg-hex-F5F7F9">
    <!-- 背景 -->
    <div class="bg"></div>

    <!-- 列表 -->
    <div class="relative" ref="list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :immediate-check="false"
          :finished="finished"
          :finished-text="list.length ? '没有更多了' : '暂无数据'"
          @load="onLoad"
        >
          <!-- 项 -->
          <div
            v-for="(item, index) in list"
            :key="index"
            class="bg-white rounded-4 mx-12 mt-12"
          >
            <div class="text-16 font-bold py-16 px-12">
              {{ item.customerName }} - {{ item.bizNo }}
            </div>

            <!-- 属性 -->
            <div
              v-for="(item1, index1) in [
                {
                  label: '联系人',
                  value: `${
                    relationshipList.find((it) => it.value == item.relationship)
                      ?.displayName || ''
                  }-${item.name || ''}-${item.mobile || ''}`,
                },
                {
                  label: '催收结果',
                  value: item.collectionResult,
                },
                {
                  label: '是否结清',
                  value: item.settleStatus,
                },
                {
                  label: '是否继续催收',
                  value: item.continueCollectStatus,
                },
                {
                  label: '跟进措施',
                  value: item.measures,
                },
                {
                  label: '催收人',
                  value: item.collectorName,
                },
                {
                  label: '催收日期',
                  value: item.collectionDate,
                },
              ]"
              :key="index1"
            >
              <!-- 灰线 -->
              <div v-if="index1 > 0" class="h-1px bg-hex-F2F5F5 mx-12"></div>

              <!-- 行 -->
              <div class="flex text-14 leading-none py-16 px-12">
                <div class="text-hex-666">{{ item1.label }}：</div>
                <div>{{ item1.value || "-" }}</div>
              </div>

              <!--  -->
            </div>

            <!--  -->
          </div>

          <!--  -->
        </van-list>
      </van-pull-refresh>

      <!--  -->
    </div>

    <!--  -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import { collectionFindPagePost } from "@/api";

export default {
  data() {
    return {
      /**
       * 列表
       */
      list: [],
      pageNo: 1,
      pageSize: 10,

      /**
       * 上下拉刷新
       */
      refreshing: false,
      loading: false,
      finished: false,

      //
    };
  },
  computed: {
    ...mapState("enums", ["relationshipList"]),

    //
  },
  mounted() {
    this.getList();

    //
  },
  methods: {
    // 上拉加载
    onLoad() {
      (this.pageNo += 1) && this.getList();
    },

    // 下拉刷新
    onRefresh() {
      (this.pageNo = 1) && this.getList();
    },

    // 获取 列表
    async getList({ pageNo = this.pageNo, pageSize = this.pageSize } = {}) {
      try {
        if (pageNo == 1) {
          this.$toast.loading({ duration: 0 });
          this.$refs["list"].scrollTo({ top: 0, behavior: "smooth" });
        }

        const { bizNo, type, recordNo } = this.$route.query;

        const data = await collectionFindPagePost({
          pageNo,
          pageSize,
          bizNo,
          collectionSourceList: [type],
          recordNo,
          flag: false,
        });

        this.refreshing = this.loading = false;
        const len = data.list.length;

        this.list = data.pageNo == 1 ? data.list : [...this.list, ...data.list];
        this.finished = len < pageSize;

        //
      } catch (err) {
        setImmediate(() => this.$toast(err));
      } finally {
        this.$toast.clear();
      }
    },

    //
  },
};
</script>
<style lang="scss" scoped>

.bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 284px;
  background: linear-gradient(180deg, #1890ff 0%, rgba(245, 247, 249, 0) 100%);
  opacity: 0.8;
}

</style>